.map {
    &-wrapper {
        height: 100vh;
        width: 100vw;
        position: relative;

        img {
            max-width: initial;
        }
    }

    &-img {
        width: 1800px;
        height: 1062px;

        position: relative;
    }
}