*:focus {
    outline: none !important;
}

html {
    font-size: 10px;
}

body {
    background-color: $body-bg;
    fill: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Formular', Arial, sans-serif;

    // background: {
    //     image: url(../img/svg/bg.svg);
    //     position: left top;
    //     repeat: no-repeat;
    //     size: contain;
    // };

}

img {
    max-width: 100%;
    height: auto;
}

button,
.btn,
a {
    transition:
        color $transition,
        background $transition,
        border-color $transition,
        fill $transition,
        opacity $transition;
    outline: none !important;
}

textarea {
    resize: vertical;
}

abbr[title] {
    text-decoration: none;
    border-bottom: 0;
    cursor: inherit !important;
}


@include media-breakpoint-down(lg) {
    
    main {
        padding-top: 100px;
    }
    
}

@include media-breakpoint-up(lg) {
    
    main {
        padding-top: $o                                                 -lg;
    }
    
}