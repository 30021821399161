.o-slide  {

    &__wrap {
        margin-bottom: $g;
        padding-bottom: 50px;
        
        &:not(:last-child) {
            border-bottom: 1px solid #000;
        }
    }
    
    &__head {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        margin-bottom: $g-sm;

        .h2 {
            margin-right: $g-sm;
        }

        .show-map {
            background-color: transparent;
            border: none;
            border-bottom: 1px dotted #000;

            text-transform: uppercase;
            font-size: 1.6rem;

            padding: 0;

            cursor: pointer;
            transition: $transition;

            &:hover {
                opacity: .7;
            }
        }
    }

    &__slider {
        position: relative;
        margin-bottom: $o;
        
        .swiper-container:first-child {
            margin-bottom: $o-xs;
        }
    }
}

.swiper-lazy {
    padding-bottom: 300px;
    position: relative;

    &-loaded {
        padding-bottom: 0 !important;
    }
}

.c-slide {
    max-height: 300px;
    height: auto;
    
    display: flex;
    align-items: center;
    justify-content: center;
    
    @include lazy_overlay;

    &::after {
        @include font-h1;
    }
    
    a {
        display: block;
        height: 100%;
        width: 100%;
        cursor: zoom-in;

        &::before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 65.64%;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;

            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    &__preview {
        cursor: pointer;
        
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;

            min-width: 80px;
            min-height: 80px;


            &.swiper-lazy-loaded {
                min-width: 50px;
            }
        }

        &::before {
            content: '';

            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            display: block;
            background-color: #0009;
            transition: $transition;

            z-index: 1;
        }

        @include lazy_overlay;

        &::after {
            font-size: 2rem;
        }

        &:hover::before {
            opacity: .8;
        }

        &.swiper-slide-thumb-active {
            &::before {
                display: none;
            }

        }
    }

}

@include media-breakpoint-down(xs) {
    .c-slide__preview {
        height: 80px !important;
    }
}

@include media-breakpoint-down(sm) {
    .o-slide__arrow {
        bottom: 13px;
        
        &.next {
            right: -$g-xs;
            transform: translateY(-50%);
        }
        
        &.prev {
            left: -$g-xs;
            transform: translateY(-50%) rotate(180deg);
        }
    }

    .c-slide__preview {
        height: 90px !important;
    }
    
}

@include media-breakpoint-up(sm) {
    .c-slide {
        max-height: 400px;
    }
    
    .swiper-lazy {
        padding-bottom: 400px;
    }
}


@include media-breakpoint-up(md) {

    .o-slide {

        &__wrap {
            margin-bottom: $o-lg;
        }

        &__slider {
            display: flex;

            .swiper-container {
                max-height: 550px;

                &:first-child {
                    width: calc(100% - 150px);
                    margin-right: $g-sm;
                    margin-bottom: 0;
                }

                &:nth-child(2) {
                    width: 130px;
                }
            }
        }
        &__arrow {
            right: $o-sm;
            &.next {
                bottom: -$g-xs;
                transform: translateX(-50%) rotate(90deg);
            }
            
            &.prev {
                top: -$g-xs;
                transform: translateX(-50%) rotate(-90deg);
            }
        }
        
    
    }

    .c-slide {
        max-height: 550px;

        &__preview {
            width: 130px !important;

            img {
                min-width: 90px;
                min-height: 90px;
            }
        }
    }

    .swiper-lazy {
        padding-bottom: 550px;
    }

}

@include media-breakpoint-up(lg) {
    .o-slide {
        max-width: 1000px;
    }
}

@include media-breakpoint-up(xl) {
 
    .o-slide {
        width: 80%;
        padding-bottom: $o-lg;
        
        &__wrap {
            padding-bottom: $o-xs;
            
            &:not(:last-child) {
                border-bottom: none;
                
                .o-slide {
                    border-bottom: 1px solid #000;
                }
            }
        }
    }   

}