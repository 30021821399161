.c-marker {
    position: absolute;

    &__number {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -60%);

        color: $white;
        font-size: 2.2rem;

        z-index: 2;
    }

    &__tooltip {
        position: absolute;
        left: -$g-lg;
        right: -$g-lg;
        bottom: 100%;

        text-align: center;
        transform: translateY(10px) scale(.7);
        transition: $transition;
        
        opacity: 0;
        pointer-events: none;
        z-index: 1000;
        
        span {
            display: inline-block;
            position: relative;
            margin: 0 auto;
            
            background: $white;
            padding: $o-xs;

            text-align: left;
            font-size: 1.4rem;

            border: 1px solid $gray-600;
            border-radius: $g-xs;
            z-index: 2;

            &::after,
            &::before {
                content: '';

                position: absolute;
                left: 50%;
                top: 100%;

                transform: translateX(-50%);

                display: inline-block;
                width: 0;
                height: 0;
            }
            
            &::after {
                border: $o-xs solid transparent;
                border-top-color: $white;
                z-index: 1;
            }
            
            &::before {
                border: 11px solid transparent;
                border-top-color: $gray-600;
            }

        }
    }

    &:hover {
        .c-marker__tooltip {
            opacity: 1;
            transform: translateY(-10px) scale(1);
        }
    }

    svg {
        position: relative;
        fill: #000;
        transition: $transition;
        z-index: 2;
    }

    &.is-active {
        svg {
            fill: $primary;
            stroke: #fff {
                width: 2px;
            };
        }
    }
}