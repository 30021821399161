@mixin padding-container {
    padding-left: $o-sm;
    padding-right: $o-sm;
    
    margin: 0 auto;
    max-width: 1440px;
    
    @include media-breakpoint-up(md) {
        & {
            padding-left: $g-sm;
            padding-right: $g-sm;
        }
    }
    
    @include media-breakpoint-up(lg) {
        & {
            padding-left: $o;
            padding-right: $o;
        }
    }
    
    @include media-breakpoint-up(xl) {
        & {
            padding-left: $g;
            padding-right: $g;
        }
    }
}

@mixin font-h1 {
    font-size: 3rem;

    @include media-breakpoint-up(sm) {
        & {
            font-size: 3.8rem;
        }
    }

    @include media-breakpoint-up(md) {
        & {
            font-size: 4.6rem;
        }
    }

    @include media-breakpoint-up(lg) {
        & {
            font-size: 5.2rem;
        }
    }

    @include media-breakpoint-up(xl) {
        & {
            font-size: 6.4rem;
        }
    }

    @include media-breakpoint-up(hd) {
        & {
            font-size: 7.2rem;
        }
    }
}

@mixin lazy_overlay() {
    &::after {
        content: 'ВДНГ';

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        width: 100%;
        height: 100%;

        background-color: #ddd !important;

        color: #B7BAC5;
        font-family: 'Formular';
        font-weight: 700;
        
        display: flex;
        justify-content: center;
        align-items: center;

        z-index: -1;
    }
}