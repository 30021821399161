.o-slide__arrow {
    display: none;
}
@include media-breakpoint-up(sm) {
    .o-slide__arrow {
        display: block;
        position: absolute;
        width: 51px;
        height: 35px;
    
        cursor: pointer;
        z-index: 1;
    
        .line {
            display: inline-block;
            background-color: #000;
            height: 4px;
    
            position: absolute;
            transition: .2s;
            
            &--1 {
                width: 20px;
                top: 25px;
                left: 28px;
                transform: rotate(-45deg);
            }
            
            &--2 {
                left: 0;
                top: 50%;
                width: 39px;
                transform: translateY(-50%);
            }
    
            &--3 {
                width: 24px;
                top: 8px;
                left: 28px;
                transform: rotate(45deg);
            }
        }
    
        &:hover {
            .line {
                &--1 {
                    top: 27px;
                    left: 29px;
                }
    
                &--2 {
                    transform: translate(-3px, -50%);
                }
    
                &--3 {
                    top: 6px;
                    left: 29px;
                }
            }
        }
    
        &.swiper-button-disabled {
            opacity: .3;
            
            &:hover {
                .line {
                    &--1 {
                        top: 25px;
                        left: 28px;
                    }
                    
                    &--2 {
                        transform: translateY(-50%);
                    }
            
                    &--3 {
                        top: 8px;
                        left: 28px;
                    }
                }
            }
        }
    
    }
}
