@font-face {
    font-family: 'Formular';
    src: url('../fonts/formular/Formular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/formular/Formular.otf')  format('opentype'),
        url('../fonts/formular/Formular.woff') format('woff'),
        url('../fonts/formular/Formular.ttf')  format('truetype'),
        url('../fonts/formular/Formular.svg#Formular') format('svg');
    font-weight: 400;
};
  
@font-face {
    font-family: 'Formular';
    src: url('../fonts/formular/Formular-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/formular/Formular-Medium.otf')  format('opentype'),
        url('../fonts/formular/Formular-Medium.woff') format('woff'),
        url('../fonts/formular/Formular-Medium.ttf')  format('truetype'),
        url('../fonts/formular/Formular-Medium.svg#Formular-Medium') format('svg');
    font-weight: 500;
};

@font-face {
    font-family: 'Formular';
    src: url('../fonts/formular/Formular-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/formular/Formular-Black.otf')  format('opentype'),
        url('../fonts/formular/Formular-Black.woff') format('woff'),
        url('../fonts/formular/Formular-Black.ttf')  format('truetype'),
        url('../fonts/formular/Formular-Black.svg#Formular-Black') format('svg');
    font-weight: 700;
};