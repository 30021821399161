.c-description {
    &__subtitle {
        color: $grey;
        text-transform: uppercase;
        font: {
            size: 1.6rem;
            weight: 500;
        };
    }
    
    &__list {
        display: flex;
        flex-wrap: wrap;
    }
    
    &__item {
        width: 100%;
        
        &:not(:last-child) {
            margin-bottom: $o;
        }
        
        ul {
            li {
                margin-bottom: $g-xs;
                
                font: {
                    size: 1.6rem;
                    weight: 500;
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .c-description {
        &__item {
            display: flex;
            
            ul {
                width: 50%;
                margin-left: auto;
                padding-left: $g-sm;
            }
        }
        
        &__subtitle {
            font-size: 1.6rem;
            width: 50%;
            padding-right: $g-sm;
        }
    }
}

@include media-breakpoint-up(lg) {
    .c-description {
        &__item {
            width: 50%;

            ul {
                width: calc(100% - 180px);
                padding-left: $g;
            }
    
            &:nth-child(even) {
                padding-left: $o;
            }
    
            &:nth-child(odd) {
                padding-right: $o;
            }
    
            &:last-child {
                width: 100%;
                padding: 0;
            }
        }

        &__subtitle {
            width: 180px;
            padding-right: 0;
        }
    }
}