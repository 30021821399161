.c-banner {
    @include padding-container;
}

@include media-breakpoint-down(lg) {

    .c-banner {
        display: flex;
        align-items: center;
    
        position: fixed;
        top: 0;
        width: 100%;
    
        padding-top: $o-xs;
        padding-bottom: $o-xs;
    
        box-shadow: 0 0 30px #0001;
        background-color: $white;
    
        z-index: 10;
    
        &-logo {
            width: 25px;
            z-index: 1;
            transition: $transition;
            
            .show-menu & {
                position: absolute;
                left: 50%;
                top: $g-lg;
                transform: translateX(-50%) scale(2.4);
            }
        }
    }

}

// > 992
@include media-breakpoint-up(xl) {

    .c-banner {
        margin: $o auto;
        padding-top: 0;
    }
    .c-burger {
        display: none;
    }
} 