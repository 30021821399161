.c-nav {

    &__wrap {
        position: absolute;
        top: 0;
        overflow-x: auto;
        right: $o-sm;
        left: $o-lg;
    }

    &__list {
        transition: all .2s linear;
    }

    &__item {
        color: #000;
        opacity: .6;

        text-decoration: none;

        font-size: 1.8rem;
        cursor: pointer;
        
        &.is-active {
            opacity: 1;
            font-weight: 700;

            &:hover {
                color: #000;
            }
        }

        &.next-sibling {
            font-weight: 500;
            color: #f00;
        }

        &:hover {
            text-decoration: none;
            color: #333;
            opacity: .8;
        }

        span {
            display: inline-block;
            width: 21px;
            text-align: left;
        }
    }
}

@include media-breakpoint-down(lg) {
    .c-nav {
        background-color: #fff;
        
        width: calc(100% - 50px);
        margin-left: auto;
        transition: $transition;

        &__item {
            display: inline-block;
            text-align: center;
            translate: translateY(0%);
            flex-shrink: 0;
            margin-right: $o-xs;
        }

        &__list {
            display: flex;
            align-items: center;
            
            padding: $o-sm 0 0;
        }
    }
}

@include media-breakpoint-up(md) {
    .c-nav__wrap {
        right: $g-sm;
        left: 70px;
    }
}

@include media-breakpoint-up(lg) {
    .c-nav__wrap {
        right: $o;
        left: $g-lg;
    }
}

@include media-breakpoint-up(xl) {
    .c-nav {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;

        display: flex;
        align-items: center;

        &__wrap {
            position: relative;
            left: 0 !important;
            right: 0 !important;

            width: 20vw;
            height: 90vh;
            max-height: 90vh;
            overflow: hidden;
            
            &::before,
            &::after {
                display: none;
            }
        }

        &__list {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;

            height: 100%;
            overflow: visible;
            padding-left: 0;
        }
        
        &__item {
            display: block;
            padding: $g-xs 0;
            font-size: 1.5rem;
            position: relative;
            transform: translateX(20px);
            
            &::after {
                content: '';

                display: inline-block;
                position: absolute;
                top: 50%;
                right: 0;
                
                width: 45px;
                height: 1px;

                background: #000;
            }

            &-inner {
                width: 80%
            }

            &:not(:last-child) {
                margin-bottom: $o-xs;
            }
            
            &.is-active {
                transform: translateX(1px);
                font-size: 1.6rem;
                
                &:hover {
                    color: #000;
                }
            }
            
            &:hover {
                color: #333;
            }
        } 
    }
}

@include media-breakpoint-up(laptop) {
    .c-nav {

        &__wrap {
            width: 17vw;
            max-width: 270px;
        }

        &__item {
            font-size: 1.6rem;

            &.is-active {
                font-size: 1.8rem;
            }
        }
    }
}