.modal {
    padding: 0 !important;

    &-open {
        .modal {
            overflow: hidden;
        }
    }

    &-content {
        border: none;
    }

    .btn-close {
        position: absolute;
        top: $o-sm;
        right: $o-sm;

        width: $g;
        height: $g;
        padding: $g-xs;

        cursor: pointer;


        background-color: $white;
        border: none;
        transition: $transition;

        z-index: 2;

        &::before,
        &::after {
            content: '';

            position: absolute;
            top: 50%;
            left: 50%;

            width: $o;
            height: $g-xs;

            background-color: $primary;
        }

        &::before {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &::after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
        
        &:hover {
            transform: scale(1.1);
            opacity: .8;
        }
    }
}