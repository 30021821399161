.o-box {
    @include padding-container;
}

.list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.h1, .h2 {
    text-transform: uppercase;
}

.h1 {
    margin: $o-sm 0 $g;
    @include font-h1;
}

.h2 {
    font-size: 2.2rem;
}

.contacts {
    margin: $g-lg 0;
    font-size: 1.8rem;
    
    &-block {
        margin-bottom: $g-sm;

        a {
            color: #000;
            text-decoration: none;
            font-weight: 500;
            
            &:hover {
                color: $primary;
                text-decoration: none;
            }
        }
    }
}

.top-svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    
    img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        width: 100%;
        z-index: -1;
    }
}

@include media-breakpoint-up(sm) {
    
    .h2 {
        font-size: 2.4rem;
    }
    
    .contacts {
        display: flex;
        flex-wrap: wrap;

        &-block:not(:last-child) {
            margin-right: $g;
        }
    }

}

@include media-breakpoint-up(md) {

    .h1 {
        margin-bottom: $o-lg;
    }

    .h2 {
        font-size: 2.6rem;
    }

    .contacts {
        font-size: 2rem;

        &-block:not(:last-child) {
            margin-right: $o-lg;
        }
    }

}

@include media-breakpoint-up(xl) {

    .h1 {
        margin-bottom: $o * 3;
    }

    .contacts {
        font-size: 2.4em;
    }

}