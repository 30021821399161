.c-seo {
    margin: $g 0;
    p {
        font-size: 1.6rem;
        margin: 0;
    }
}

@include media-breakpoint-up(md) {
    .c-seo {
        margin: $o-lg 0;
    }
}